<div class="container" *ngIf="events$ | async as events">
    <h1 class="title">
        <a href="https://community.complicated.life/c/upcoming-events-itcomplicated/" target="_blank" class="no-style-anchor"
           rel="noopener noreferrer">{{ 'upcoming_events.title' | translate }}</a>
    </h1>
    <div *ngFor="let event of events" class="box">
        <a [href]="event.url" target="_blank" rel="noopener noreferrer" class="no-style-anchor">
            <div class="content">
                <strong>{{ event.name }}</strong> <br>
                <small>{{ event.starts_at | date: 'mediumDate' }}
                    - {{ event.ends_at | date: 'shortTime' }}</small>
                <br>
                <span class="tag is-primary is-rounded has-text-weight-medium- has-margin-top-15"
                      style="border-radius: 6px">{{ 'upcoming_events.location_types.' + event.location_type | translate }}</span>
            </div>
        </a>
    </div>
</div>
